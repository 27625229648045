import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import icon from './../assets/img/favicon.png';

function Header() {
  return (
    <StaticQuery
      query={graphql`
        {
          mongodbCloserDevCmsPages(siteId: { eq: "5dac524cf761b3073f3ee47f" }) {
            name
            content {
              title
            }
          }
        }
      `}
      render={(data) => getContent(data.mongodbCloserDevCmsPages)}
    />
  );
}

function getContent(data) {
  //   console.log(data);
  return (
    <section id='banner' className='bImg'>
      <div className='inner'>
        <Link to='/'>
          <div className='logo'>
            {/* <span className="icon fa-diamond"></span> */}
            <img
              style={{ maxWidth: '250px' }}
              loading={'lazy'}
              alt='mline'
              src={icon}
            ></img>
          </div>
        </Link>
        <h2>{data.name}</h2>
        <p>{data.content.title}</p>
      </div>
    </section>
  );
}

export default Header;
